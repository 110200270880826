
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomAvatarListItem',
  props: {
    userId: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    pinSelectedItem: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    getClasses() {
      return {
        [this.className]: this.className,
        selected: this.selected,
        pinSelected: this.pinSelectedItem,
      };
    },
  },
});
