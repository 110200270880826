
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomSwitch',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitChange(event) {
      const { checked } = event.target;
      this.$emit('change', checked);
    },
  },
});
