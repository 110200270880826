
/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { BTooltip } from 'bootstrap-vue';
import { block, unblock } from '@/api/community/communityApi';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomAvatarList from '@/components/atoms/AtomAvatarList.vue';
import AtomAvatarListItem from '@/components/atoms/AtomAvatarListItem.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomSwitch from '@/components/atoms/AtomSwitch.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

import RouteNamesEnum from '@/router/RouteNamesEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';
import GamificationTypeEnum from '@/enums/gamification/GamificationTypeEnum';

import { hasFeature } from '@/services/feature/FeatureService';
import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';
import reloadOTBanner from '@/services/oneTrust/oneTrustBanner';

import { setOptIn, removeOptIn } from '@/api/gamification/gamificationApi';

export default Vue.extend({
  name: 'TemplateSettingsSecurity',
  components: {
    AtomSwitch,
    AtomAvatar,
    AtomAvatarListItem,
    AtomAvatarList,
    AtomButton,
    AtomSetup,
    AtomSvgIcon,
    AtomText,
    AtomActionText,
    AtomTextInput,
    BTooltip,
    MoleculeModal,
    OrganismPageSubHeader,
  },
  data: () => ({
    nameToBlock: {
      firstName: '',
      lastName: '',
    },
    FeaturesEnum,
    Features,
    RouteNamesEnum,
    ButtonVariant,
    AvatarSize,
  }),
  computed: {
    ...mapGetters('user', [
      'getUserId',
      'site',
      'getBlockedUsers',
      'hasMadeGamingDecision',
      'hasMadeLotteryDecision',
      'hasAcceptedLeaderBoard',
      'hasAcceptedExperience',
      'hasAcceptedLottery',
      'features',
    ]),
    showLottery(): boolean {
      return (
        isEnvReady(Features.GAMIFICATION_LOTTERY)
        && !hasFeature(this.features, FeaturesEnum.GAMIFICATIONOPTOUT_LOTTERY)
        && this.hasMadeLotteryDecision
      );
    },
  },
  methods: {
    ...mapActions('user', ['deleteBlockedUser', 'addBlockedUser', 'fetchBlockedUsers', 'setGamificationStatus']),
    hasFeature,
    isEnvReady,
    showLotteryButton() {
      const now = moment();
      const startDate = moment('2023-04-11').startOf('day');
      const endDate = moment('2023-06-01').startOf('day');

      return now.isAfter(startDate) && now.isBefore(endDate);
    },
    async unblockUser(user) {
      const { status } = await unblock(user.id);
      if (status === 200) {
        await this.deleteBlockedUser(user.id);
        toast(this.$bvToast, this.$t('otherProfile.unblockMessage', { name: `${user.firstName} ${user.lastName}` }).toString(), ToastVariants.SUCCESS);
      }
    },
    async blockPerson() {
      const { status, data } = await block(this.getUserId, this.nameToBlock.firstName, this.nameToBlock.lastName);
      if (status === 201) {
        await this.addBlockedUser(data);
        toast(this.$bvToast, this.$t('otherProfile.blockMessage').toString(), ToastVariants.SUCCESS);
        this.nameToBlock.firstName = '';
        this.nameToBlock.lastName = '';
      }
    },
    async toggleExperience(status: boolean): Promise<void> {
      if (status) {
        await setOptIn(GamificationTypeEnum.EXPERIENCE);
        const { data } = await setOptIn(GamificationTypeEnum.LEADER_BOARD);
        if (data) this.setGamificationStatus(data.gamification.status);
      } else {
        await removeOptIn(GamificationTypeEnum.EXPERIENCE);
        const { data } = await removeOptIn(GamificationTypeEnum.LEADER_BOARD);
        if (data) this.setGamificationStatus(data.gamification.status);
      }
    },
    async toggleAcceptedLottery(status: boolean): Promise<void> {
      if (status) {
        const { data } = await setOptIn(GamificationTypeEnum.LOTTERY);
        if (data) this.setGamificationStatus(data.gamification.status);
      } else {
        const { data } = await removeOptIn(GamificationTypeEnum.LOTTERY);
        if (data) this.setGamificationStatus(data.gamification.status);
      }
    },
  },
  mounted() {
    this.fetchBlockedUsers();
    reloadOTBanner();
  },
});
