function reloadOTBanner() {
  const { OneTrust } = window as any;

  if (OneTrust != null) {
    OneTrust.Init();
    setTimeout(() => {
      OneTrust.LoadBanner();

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < toggleDisplay.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        toggleDisplay[i].onclick = (event) => {
          event.stopImmediatePropagation();
          OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
}

export default reloadOTBanner;
